<template>
  <div v-if="showComponent">
    <!--START: Vue Headful-->
    <vue-headful :title="meta.title" :description="meta.description" />
    <!--END: Vue Headful-->

    <!--START: Dashboard Header-->
    <DashboardHeader
      v-if="!$route.meta.hideHeader"
      @toggleCompany="toggleCompany"
      @showTaskList="showTaskList"
    ></DashboardHeader>
    <!--END: Dashboard Header-->

    <!--START: How to use button-->
    <a
      href="https://www.youtube.com/watch?v=9joQqhesR20"
      target="_blank"
      class="how-to-use"
    >
      <unicon name="play"></unicon>
      <span>How to use Macha</span>
    </a>
    <!--END: How to use button-->

    <!--START: Router View-->
    <router-view id="dashboard-main" :key="$route.fullPath"></router-view>
    <!--END: Router View-->

    <!--START: Subscription-->
    <SubscriptionBanner
      v-if="company?.subscription?.status !== 'active'"
    ></SubscriptionBanner>
    <!--END: Subscription-->

    <!--START: Task list-->
    <TaskList
      v-if="company?.subscription?.showTaskList || showOptions.taskList"
      @closeModal="closeModal"
    ></TaskList>
    <!--END: Task List-->

    <!--START: Loader-->
    <LineLoader :show="showOptions.lineLoader"></LineLoader>
    <!--END: Loader-->
  </div>
</template>
  
<script>
// Import components
import DashboardHeader from "@/components/dashboard/Header";
import SubscriptionBanner from "@/components/dashboard/SubscriptionBanner";
import TaskList from "@/components/dashboard/TaskList";
import LineLoader from "@/components/loaders/LineLoader";

// Importing services
import { AuthService, CompanyService } from "@/services";

export default {
  name: "Dashboard",
  data() {
    return {
      showComponent: false,
      showOptions: {
        lineLoader: false,
        taskList: false,
      },
      meta: {
        title: "Dashboard - Macha",
        description: "",
      },
    };
  },

  components: {
    DashboardHeader,
    SubscriptionBanner,
    TaskList,
    LineLoader,
  },

  computed: {
    company() {
      return this.$store.getters.company;
    },
  },

  watch: {
    $route(to) {
      if (!to.meta?.authRoute) this.checkAccess();
      if (to.name === "Logout") this.logoutUser();
    },
  },

  async created() {
    await this.checkAccess();
  },
  methods: {
    // Check if dashboard access can be granted
    async checkAccess() {
      // Set body class to dashboard
      document.body.className = "dashboard";

      // Check to see if the staff is authenticated
      if (await this.authenticateUser()) {
        this.showComponent = true;
      } else {
        this.showComponent = false;
        this.redirectSignin();
      }
    },

    // Toggle the company
    async toggleCompany(data) {
      this.showOptions.lineLoader = true;
      await AuthService.ToggleCompany(data);

      this.$router.go();
    },

    // Show the task list
    showTaskList() {
      this.showOptions.taskList = true;
    },

    async closeModal() {
      this.showOptions.taskList = false;

      // Disable the task list modal
      if (this.company.subscription.showTaskList) {
        const response = await CompanyService.DisableOnboarding();
        this.$store.commit("updateCompany", response.data);
      }
    },
  },
};
</script>
  
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";
.dashboard-view {
  margin: 4.15rem 0 0;
}

/deep/ {
  .line-loader {
    position: fixed;
    bottom: 0;
  }
}

.how-to-use {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 10;
  background: #1e1e1e;
  border-radius: 3rem;
  border: 1px solid lighten(#1e1e1e, 10%);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.65rem 0.85rem;
  text-decoration: none;
  transition: all 0.3s;
  cursor: pointer;

  .unicon /deep/ svg {
    height: auto;
    width: 0.85rem;
    margin-right: 0.5rem;
    fill: $whiteColor;
    transform: translateY(1px);
  }

  span {
    color: $whiteColor;
    font-size: $smallFontSize;
    line-height: 1;
  }

  &:hover {
    border: 1px solid lighten($purpleColor, 10%);
    background: $purpleColor;
  }
}
</style>