import Vue from "vue";
import VueRouter from "vue-router";

// START: Authentication Views
import Register from "@/views/Authentication/Register";
import Login from "@/views/Authentication/Login";
import Logout from "@/views/Authentication/Logout";
import StoreSelection from "@/views/Authentication/StoreSelection";
import AuthenticateLogin from "@/views/Authentication/AuthenticateLogin";
import SelectCompany from "@/views/Authentication/SelectCompany";
import Onboarding from "@/views/Authentication/Onboarding";
// END: Authentication Views

// START: Dashboard Views
import Dashboard from "@/views/Dashboard";

import Inbox from "@/views/Inbox/Index";

import AIBehavior from "@/views/AIBehavior/Index";
import AnswerRules from "@/views/AIBehavior/AnswerRules";
import Personas from "@/views/AIBehavior/Personas";

import KnowledgeBase from "@/views/KnowledgeBase/Index";
import ViewArticles from "@/views/KnowledgeBase/Articles/ViewArticles";
import AddArticle from "@/views/KnowledgeBase/Articles/AddArticle";
import Sources from "@/views/KnowledgeBase/Sources/Sources";
import Documents from "@/views/KnowledgeBase/Sources/Documents";

import Analytics from "@/views/Analytics/Index";
import Insights from "@/views/Analytics/Insights";

import Settings from "@/views/Settings/Index";
import CompanyDetails from "@/views/Settings/CompanyDetails";
import Integrations from "@/views/Settings/Integrations";
import UserAccess from "@/views/Settings/UserAccess";
import Subscriptions from "@/views/Settings/Subscriptions";

import ZendeskSuccess from "@/views/Misc/Zendesk/Success";
import ZendeskError from "@/views/Misc/Zendesk/Error";
// END: Dashboard Views

Vue.use(VueRouter);

export default new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      redirect: "/sign-in",
      meta: { authRoute: true },
    },
    {
      path: "/sign-in",
      name: "Login",
      component: Login,
      meta: { authRoute: true },
    },
    {
      path: "/logout",
      name: "Logout",
      component: Logout,
      meta: { authRoute: true },
    },
    {
      path: "/store-selection",
      name: "StoreSelection",
      component: StoreSelection,
      meta: {
        hideHeader: true,
      },
    },
    {
      path: "/authenticate-login",
      name: "AuthenticateLogin",
      component: AuthenticateLogin,
      meta: { authRoute: true },
    },
    {
      path: "/select-company",
      name: "SelectCompany",
      component: SelectCompany,
      meta: { authRoute: true },
    },
    {
      path: "/sign-up",
      name: "Register",
      component: Register,
      meta: { authRoute: true },
    },

    {
      path: "/onboarding",
      name: "Onboarding",
      component: Onboarding,
      meta: { authRoute: true },
    },

    {
      path: "/",
      name: "Dashboard",
      component: Dashboard,
      redirect: "/inbox",
      children: [
        {
          path: "inbox",
          name: "Inbox",
          component: Inbox,
        },
        {
          path: "ai-behavior",
          name: "AI Behavior",
          component: AIBehavior,
          redirect: "ai-behavior/personas",
          children: [
            {
              path: "personas",
              name: "AI Personas",
              component: Personas,
            },
            {
              path: "answer-rules",
              name: "Answer Rules",
              component: AnswerRules,
            },
          ],
        },
        {
          path: "knowledge-base",
          name: "Knowledge Base",
          component: KnowledgeBase,
          redirect: "knowledge-base/sources",
          children: [
            {
              path: "articles",
              name: "View Articles",
              component: ViewArticles,
            },
            {
              path: "articles/add/:referenceID?",
              name: "Add Article",
              component: AddArticle,
            },
            {
              path: "sources",
              name: "Sources",
              component: Sources,
            },
            {
              path: "sources/:sourceID",
              name: "Source Documents",
              component: Documents,
            },
          ],
        },
        {
          path: "analytics",
          name: "Analytics",
          component: Analytics,
          redirect: "analytics/insights",
          children: [
            {
              path: "insights",
              name: "Insights",
              component: Insights,
            },
          ],
        },
        {
          path: "settings",
          name: "Settings",
          component: Settings,
          redirect: "settings/company-details",
          children: [
            {
              path: "company-details",
              name: "CompanyDetails",
              component: CompanyDetails,
            },
            {
              path: "integrations",
              name: "Integrations",
              component: Integrations,
            },
            {
              path: "user-access",
              name: "UserAccess",
              component: UserAccess,
            },
            {
              path: "subscriptions",
              name: "Subscriptions",
              component: Subscriptions,
            },
          ],
        },
      ],
    },

    {
      path: "",
      name: "Dashboard",
      component: Dashboard,
      children: [
        {
          path: "settings",
          name: "Settings - Dashboard",
          component: Dashboard,
          children: [
            {
              path: "zendesk/oauth/success",
              name: "ZendeskSuccess",
              component: ZendeskSuccess,
              meta: {
                hideHeader: true,
              },
            },
            {
              path: "zendesk/oauth/error",
              name: "Error",
              component: ZendeskError,
              meta: {
                hideHeader: true,
              },
            },
          ],
        },
      ],
    },

    {
      path: "*",
      beforeEnter() {
        location.href = "https://www.getmacha.com";
      },
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
  },
});
