<template>
  <div>
    <div
      v-for="(field, name) in fields"
      :key="field.id"
      class="field-block-item"
    >
      <div
        v-if="field.show !== false"
        class="field-main-item"
        :class="field.additionalClass != undefined ? field.additionalClass : ''"
      >
        <!-- START: Group Title -->
        <label class="field-label" v-if="field.groupTitle != undefined">{{
          field.groupTitle
        }}</label>
        <!-- END: Group Title -->

        <!-- START: Field Title -->
        <div
          v-if="field.title != undefined"
          class="field-title"
          :class="{ 'field-title-large': field.type == 'group' }"
        >
          <label>{{ field.title }}</label>
          <div v-if="field.info" class="hidden-info">
            <em>i</em>
            <p>{{ field.info }}</p>
          </div>
        </div>
        <!-- END: Field Title -->

        <!-- START: Radio Buttons -->
        <RadioButton
          v-if="field.type == 'radio'"
          :radioField="field"
          class="field-wrapper"
          @change="fieldChanged()"
        ></RadioButton>
        <!-- END: Radio Buttons -->

        <!-- START: Radio Buttons -->
        <ToggleButton
          v-else-if="field.type == 'toggle'"
          :field="field"
          :toggleName="name"
          class="field-wrapper"
          @fieldChanged="fieldChanged()"
        ></ToggleButton>
        <!-- END: Radio Buttons -->

        <!-- START: Radio Buttons -->
        <MultipleToggleButton
          v-else-if="field.type == 'multiple-toggle'"
          :field="field"
          :toggleName="name"
          class="field-wrapper"
          @fieldChanged="fieldChanged()"
        ></MultipleToggleButton>
        <!-- END: Radio Buttons -->

        <!-- START: Datepicker -->
        <Datepicker
          v-else-if="field.type == 'date'"
          :field="field"
          class="field-wrapper"
          @fieldChanged="fieldChanged()"
        ></Datepicker>
        <!-- END: Datepicker -->

        <!-- START: File Upload Fields -->
        <FileUploadLarge
          v-else-if="field.type == 'file-large'"
          :field="field"
          class="field-wrapper"
          @change="fieldChanged()"
          @fileUploaded="fileUploaded"
        ></FileUploadLarge>
        <!-- End: File Upload Fields -->

        <!-- START: Profile Image Uploader Fields -->
        <ProfileImageUploader
          v-else-if="field.type == 'profile-image'"
          :field="field"
          class="field-wrapper profile-image-field-wrapper"
          @change="fieldChanged()"
          @fileUploaded="fileUploaded"
        ></ProfileImageUploader>
        <!-- END: Profile Image Uploader Fields -->

        <!-- START: Group Fields -->
        <GroupFormFields
          v-else-if="field.type == 'group'"
          class="field-wrapper field-group-wrapper"
          :class="[
            field.additionalClass,
            `field-count-${Object.keys(field.fields).length}`,
            field.expandFields == true ? 'field-group-expand' : '',
          ]"
          :fields="field.fields"
          @fieldChanged="fieldChanged()"
        ></GroupFormFields>
        <!-- END: Group Fields -->

        <!-- START: Money Input -->
        <MoneyField
          v-else-if="field.type == 'money'"
          :field="field"
          class="field-wrapper"
          @fieldChanged="fieldChanged()"
        ></MoneyField>
        <!-- END: Money Input -->

        <!-- START: Phone Number Input -->
        <TelephoneField
          v-else-if="field.type == 'tel'"
          :field="field"
          class="field-wrapper"
          @fieldChanged="fieldChanged()"
        ></TelephoneField>
        <!-- END: Phone Number Input -->

        <!-- START: Phone Number Input -->
        <CountryTelephoneField
          v-else-if="field.type == 'country-tel'"
          :field="field"
          class="field-wrapper"
          @fieldChanged="fieldChanged()"
        ></CountryTelephoneField>
        <!-- END: Phone Number Input -->

        <!-- START: Color Picker Input -->
        <ColorPicker
          v-else-if="field.type == 'color-picker'"
          :field="field"
          @fieldChanged="fieldChanged()"
        ></ColorPicker>
        <!-- END: Color Picker Input -->

        <!-- START: Multiple Select Dropdown Input -->
        <Dropdown
          v-else-if="field.type == 'dropdown'"
          :field="field"
          :class="{ error: field.hasError }"
          class="field-wrapper"
          @fieldChanged="fieldChanged()"
        ></Dropdown>
        <!-- START: Multiple Select Dropdown Input -->

        <!-- START: Multiple Select Dropdown Input -->
        <MultipleSelectDropdown
          v-else-if="field.type == 'multiple-select-dropdown'"
          :field="field"
          :class="{ error: field.hasError }"
          class="field-wrapper"
          @change="fieldChanged()"
        ></MultipleSelectDropdown>
        <!-- START: Multiple Select Dropdown Input -->

        <!-- START: Icon Selector -->
        <IconSelector
          v-else-if="field.type == 'icon-selector'"
          :field="field"
          :class="{ error: field.hasError }"
          class="field-wrapper"
          @fieldChanged="fieldChanged()"
        ></IconSelector>
        <!-- START:  Icon Selector -->

        <!-- START: Textarea -->
        <div v-else-if="field.type == 'textarea'" class="field-wrapper">
          <textarea
            rows="4"
            :name="field.name"
            :placeholder="field.placeholder"
            :required="field.required"
            :readonly="field.readOnly != undefined ? field.readOnly : false"
            :class="{ error: field.hasError }"
            :maxlength="field.maxlength"
            spellcheck="false"
            @focus="field.hasError = false"
            @input="fieldChanged()"
            v-model="field.value"
          ></textarea>
        </div>
        <!-- END: Textarea -->

        <!-- START: Text Editor -->
        <div v-else-if="field.type == 'text-editor'" class="field-wrapper">
          <vue-editor
            v-model="field.value"
            class="text-editor"
            :name="field.name"
            :editorToolbar="
              field.showMiniToolbar == true ? editorToolbar : undefined
            "
            :class="{ error: field.hasError }"
            @focus="field.hasError = false"
            @text-change="editorTextChange"
            :placeholder="field.placeholder"
            :required="field.required"
          ></vue-editor>
        </div>
        <!-- END: Text Editor -->

        <!-- START: Scheduler -->
        <div v-else-if="field.type == 'scheduler'" class="field-wrapper">
          <Scheduler :field="field" @fieldChanged="fieldChanged()"></Scheduler>
        </div>
        <!-- END: Scheduler -->

        <!-- START: OTP Field -->
        <div v-else-if="field.type == 'otp'" class="field-wrapper">
          <OTPField :field="field" @OTPChanged="OTPChanged"></OTPField>
        </div>
        <!-- END: OTP Field -->

        <!-- START: Credit Card Field -->
        <div v-else-if="field.type == 'credit-card'" class="field-wrapper">
          <CreditCard :field="field"></CreditCard>
        </div>
        <!-- END: Credit Card Field -->

        <!-- START: Input Fields -->
        <div v-else class="field-wrapper" :class="`type-${field.type}`">
          <input
            :type="field.type"
            :name="field.name"
            :placeholder="
              field.showEditButton && field.emptyPlaceholder
                ? field.emptyPlaceholder
                : field.placeholder
            "
            :min="field.min"
            :max="field.max"
            :required="field.required"
            :value="field.id"
            :readonly="field.readOnly != undefined ? field.readOnly : false"
            :class="[
              { error: field.hasError },
              { 'value-only': field.showEditButton },
            ]"
            :disabled="field.showEditButton || field.disabled"
            spellcheck="false"
            @focus="fieldFocused(field)"
            @input="fieldChanged()"
            @change="fieldChanged()"
            v-model="field.value"
          />
          <button
            v-if="field.showEditButton"
            class="btn btn-small btn-text btn-field-edit btn-no-padding"
            @click="makeFieldEditable(field)"
          >
            <unicon name="pen"></unicon><span>Edit</span>
          </button>
        </div>
        <!-- END: Input Fields -->

        <!--START: Alert Type-->
        <div class="alert-message-wrapper">
          <div v-if="field.alertMessage != undefined" class="alert-message">
            <div v-if="field.alertMessage.type == 'html'" class="alert-html">
              <span v-html="field.alertMessage.message"></span>
            </div>
            <div
              v-else
              class="alert-notification"
              :class="field.alertMessage.color"
            >
              <unicon name="bell"></unicon>
              <span>{{ field.alertMessage.message }}</span>
            </div>
          </div>
        </div>
      </div>
      <!--END: Alert Type-->
    </div>
  </div>
</template>

<script>
//Importing components
import RadioButton from "./RadioButton";
import ToggleButton from "./ToggleButton";
import MultipleToggleButton from "./MultipleToggleButton";
import MoneyField from "./MoneyField";
import TelephoneField from "./TelephoneField";
import CountryTelephoneField from "./CountryTelephoneField";
import FileUploadLarge from "./FileUploadLarge";
import ProfileImageUploader from "./ProfileImageUploader";
import ColorPicker from "./ColorPicker";
import Dropdown from "./Dropdown";
import Scheduler from "./Scheduler";
import MultipleSelectDropdown from "./MultipleSelectDropdown";
import Datepicker from "./Datepicker";
import { VueEditor } from "vue2-editor";
import IconSelector from "./IconSelector";
import OTPField from "./OTPField";
import CreditCard from "./CreditCard";

export default {
  name: "FormBuilder",
  props: {
    fields: {
      type: Object,
      required: true,
    },
    statusMessageHandler: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      initContent: null,
      editorToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link"],
      ],
      fileUploadID: 0,
    };
  },
  components: {
    GroupFormFields: () => import("./FormBuilder.vue"),
    RadioButton,
    ToggleButton,
    MultipleToggleButton,
    MoneyField,
    TelephoneField,
    CountryTelephoneField,
    FileUploadLarge,
    ProfileImageUploader,
    ColorPicker,
    Dropdown,
    MultipleSelectDropdown,
    Datepicker,
    VueEditor,
    Scheduler,
    IconSelector,
    OTPField,
    CreditCard,
  },
  methods: {
    editorTextChange() {
      this.fieldChanged();
    },

    clearDate(field) {
      field.value = null;
      this.fieldChanged(true);
    },

    OTPChanged(field, otpValue) {
      field.value = otpValue;
      this.fieldChanged();
    },

    makeFieldEditable(field) {
      field.showEditButton = false;
    },

    fieldChanged(propogateToParent) {
      if (propogateToParent) this.$parent.$emit("fieldChanged");
      else this.$emit("fieldChanged");
    },

    fieldFocused(field, propogateToParent = false) {
      field.hasError = false;
      if (propogateToParent) this.$parent.$emit("fieldFocused");
      else this.$emit("fieldFocused");
    },

    fileUploaded(e) {
      this.$emit("fileUploaded", e);
      this.$emit("fieldChanged");
    },

    fileUploadEleID() {
      this.fileUploadID++;
      return `assetsFieldHandle-${this.fileUploadID}`;
    },
  },
};
</script>

<style scoped lang="scss">
.alert-message {
  display: table;
  margin-top: 0.75rem;

  /deep/ .unicon svg {
    width: 1rem;
    height: auto;
    margin-right: 0.5rem;
    margin-top: 0.15rem;
  }

  span {
    font-size: $smallerFontSize;
  }

  .alert-html {
    color: $greyColor;
  }

  .alert-notification {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0.5rem 0.75rem;
    border-radius: 0.75rem;

    &.yellow {
      background-color: $warningYellowBackgroundColor;
      border: 1px solid $warningYellowBorderColor;

      span {
        color: $warningYellowColor;
      }

      /deep/ .unicon svg {
        fill: $warningYellowColor;
        height: auto;
        width: 0.85rem;
      }
    }
  }
}
</style>